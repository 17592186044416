import React, { useMemo } from "react";
import {
  Text,
  Input,
  Box,
  Flex,
  Spacer,
  Grid,
  HStack,
  Center,
  Divider,
  Button
} from "@chakra-ui/react";


import { useState, useEffect} from 'react';
import ModalButton from './modalbutton'
import LoadingSkeleton from './loadingSkeleton'
import  { useNavigate } from 'react-router-dom'
import {CustomTable} from "./customTable";
import { Stat } from "./stats";
import { UserCard } from "./userCardEc2";

import EpochTimeToLocaleString from "../utils/UTCtoLocalTime";
import { tableCols } from "../data/tableCols";
import { prepareDataForTable } from "../utils/prepareDataForTable";
import getData from "../utils/fetchData";



// redefine console.log in order to disable console outputs
//comment out to show console outputs again.
console.log = function(){}



function ReactTable(props) {
    console.log("react table envs")
    console.log(props.env)
    const API_ENDPOINT= props.env.api_url
    /*
    Main EC2Table component: heart of all functionality
    */
    
    const[instances, setInstances] = useState()
    const[actionInstance, setActionInstance] = useState()
    const[filteredInstances, setFilteredInstances] = useState([])
    const[adminType, setAdminType]=useState()
    const[currUser, setCurrUser] = useState()
    const apiName = 'ec2API';
    const [search, setSearch]= useState(null)
    const path = '/ec2';
    const [fetchData, setFetchData] = useState(true)
    


    /*
    Child componenent ModalButton uses this function as a callback to send the data to this compoennt

    params:
            duration: duration of turning on the instance
            id: instance Id
            action: start/stop
            email: email address of logged in user to initialise API request
    This data comes from the ModalButton once user puts in the value
    
    */
   //TODO: now the email attribute is a json with both ID and email change name accordingly
   
    const handlePassedData = (duration, id, action,adminType,userData, instance, justification) =>{
        console.log("instance ")
        console.log(instance)
        console.log(`duration = ${duration}, action=${action} id=${id}, email=${userData['email']}, justification=${justification}, region=${instance.region}, googletok=${userData['id_tok']} `)
        console.log("email in handlepassdata: "+userData['email'])
        let p = ""
        let urlSafeJustStr = encodeURIComponent(justification)
        if(action === "postpone"){
            p = "/ec2?user=" + userData['email'] +"&action=start" + "&instance=" + instance.id + "&duration=" + duration + "&region=" + instance.region + "&justification="+urlSafeJustStr
        }else{
            p = "/ec2?user=" + userData['email'] +"&action=" + action + "&instance=" + instance.id + "&duration=" + duration + "&region=" + instance.region+ "&justification="+urlSafeJustStr
        }

        getData(API_ENDPOINT+p, props.user["id_tok"] )
        .then(response => {
            console.log("-----response----")
            let data = response.data
            console.log(data)
            setActionInstance(response.data)
            //response.data.StoppingInstances?setInstances(replaceInstanceDescription(instances, response.data.StoppingInstances[0]))
            //:setInstances(replaceInstanceDescription(filteredInstances, response.data.StartingInstances[0]))
            if(action === "stop"){
                alert("Info: The requested instance will be stopped shortly.Please refresh the page to display the change.")   
            }else if (action === "start"){
                alert("Info: The requested instance will be started shortly. Please refresh the page to display the change.")
            }
            else{
                alert("Info: Shutdown scheduled. The instance will be shut down in the given time.")
            }
        })
        .catch(error => {
            console.log(error);
            alert(`Error: An error occured while trying to ${action} the instance . Please try again later or contact the Administrator. + error: ${error}`)
    });

    }

    /*
    Convert Unix Epoch time to date time format
    startup and shutdown time are stored as tags of ec2 instacnes in Unix epoch time

    params:
            timeEpoch: Unix Epoch Time
            offset: needed if it needs to be in another time zone. not used here.
    
    */


    const __handleSearch = (event) => {
       setSearch(event.target.value)
       console.log(event.target.value)
       var search_results = []
       search_results = instances.filter((ins) =>{
        /*
            since all names are of form prefix.acrolinx.cloud, the search results all instances
            if any letter form the domain name is the first letter of the search
            avoid this problem by first removing the domain part of the name.
            let ins_prefix = ins.tags.Name.split(".")[0]
        */
        let ins_prefix = ins.tags.Name.split(".")[0]
        return ins_prefix.includes(event.target.value.toLocaleLowerCase())
       }
     
       );
       console.log(search_results)
       setFilteredInstances(search_results)
    /*       
        if (event.target.value !== '') {
                 search_results = instances.filter((ins) =>
                ins.tags.Name.toLowerCase().includes(event.target.value.toLocaleLowerCase())
                );
                
                    setFilteredInstances(search_results)
               
        } */
    };



    /*
    description:
                update the table content (instances array) with the new state after action is taken
    @params:
            instArray: current instances array : list of objects
            instDetails: details about the instance in answer sent by the API server: object    
    
    */
    function replaceInstanceDescription(instArray, instDetails){
        const objIndex = instArray.findIndex((obj => obj.id === instDetails.InstanceId))
        console.log(instArray)
        instArray[objIndex].state = instDetails.CurrentState.Name

        return instArray
    }

    const navigate= useNavigate()
    useEffect(() =>{
                var res = props.user
                setCurrUser(res)
                const url = API_ENDPOINT+path+"?user="+(res['email'])
                console.log("url = "+ url)
                console.log("user = " + props.user["id_tok"] )
                getData(url, props.user["id_tok"] )
                .then(response => {

                    let arr = ((response.instances))
                    let adminInfo = response.userInfo.admintype
                    setAdminType(adminInfo)
                    let preparedInstances = prepareDataForTable(arr)
                    setInstances(preparedInstances)
                    setFilteredInstances(preparedInstances)
                    })
                    .catch(error => {
                        console.log(error);
                    });
    //actionInstance refers to the instance on which an action is performed
    // this is set as dependency so the table updates after an action to reflect new state
    },[])


  const columns = React.useMemo(
    () => [
        /*
        For the table columns which only reppresent information and do not have special
        functinality, e.g., buttons turning on and off, or manipulating UTC time to display
        on local time zone, these can be populated directly on the ../data/tableCols.js file
        We need a Header name and an accessor. Accessor can either be a key name of an element of
        the instances array we get from the API or arbitray if Cell is defined.
        */
          ...tableCols,
          {
            Header: "Start/Stop",
            accessor: "startstop",
            Cell: ({row})=>{
                
                return row.original.state!=="running"?(
                    <div>
                        <ModalButton
                            key={"modalButton"+1}
                            buttonColor="blue"
                            buttontitle= "Start"
                            handlePassedData={handlePassedData}
                            description="Start an EC2 instance"
                            instanceId={row.original.id}
                            action={"start"}
                            adminType={adminType}
                            userData={currUser}
                            // all the info about the instance
                            instance = {row.original}
                            env = {props.env}
                            
                        />
                    </div>
                    ):(
                        <ModalButton
                            key={"modalButton"+2}
                            buttonColor="red" 
                            buttontitle= "Stop"
                            handlePassedData={handlePassedData}
                            description="Stop an EC2 instance"
                            instanceId={row.original.id}
                            action={"stop"}
                            adminType={adminType}
                            userData={currUser}
                            env={props.env}
                            // all the info about the instance
                            instance = {row.original}
                        />

                    )
            },
            sortType: ((rowA, rowB) => {
        
                
                if (rowA.original.state > rowB.original.state) return 1; 
                if (rowB.original.state > rowA.original.state) return -1;
                return 0;
         })
                 
          },
          {
            Header: "Shutdown Time",
            accessor: "shutdownTime",
            Cell: ({row})=>{
                let timeStr = "N/A"
                if(row.original.tags.shutdown){
                    if(row.original.tags.shutdown !== "-1" && row.original.tags.shutdown !== "999999999999999"){
                        timeStr = EpochTimeToLocaleString(row.original.tags.shutdown)
                    }
                  
                }      
                return <div>{timeStr}</div>
            },
              sortType: ((rowA, rowB, id,desc) => {
                //custom sort function so that we can always put the N/A values to the last
                // desc checks if the current sorting order is ascendig or descending.
                if(desc === true){

                    // if sorting is descending put the NA values at last
                    if(!rowA.original.tags.shutdown|| rowA.original.tags.shutdown==="999999999999999") rowA.original.tags.shutdown="-1"
                    if(!rowB.original.tags.shutdown || rowB.original.tags.shutdown==="999999999999999") rowB.original.tags.shutdown="-1"
                    if (parseInt(rowA.original.tags.shutdown) > parseInt(rowB.original.tags.shutdown)) return 1; 
                    if (parseInt(rowB.original.tags.shutdown) > parseInt(rowA.original.tags.shutdown)) return -1;
                    if (parseInt(rowB.original.tags.shutdown) === parseInt(rowA.original.tags.shutdown)) return 0;
                }else{
                    // if the sorting is ascending put the NA values at first (but as sorting is -1 to 1 it gets last
                    // in the list)
                    //if(!rowA.original.tags.shutdown) return 1
                    if(!rowA.original.tags.shutdown || rowA.original.tags.shutdown==="-1") rowA.original.tags.shutdown="999999999999999"
                    if(!rowB.original.tags.shutdown || rowB.original.tags.shutdown==="-1") rowB.original.tags.shutdown="999999999999999"
                    if (parseInt(rowA.original.tags.shutdown) > parseInt(rowB.original.tags.shutdown)) return 1; 
                    if (parseInt(rowB.original.tags.shutdown) > parseInt(rowA.original.tags.shutdown)) return -1;
                    if (parseInt(rowB.original.tags.shutdown) === parseInt(rowA.original.tags.shutdown)) return 0;
                }
         })
             
           
          },
          {
            Header: "Postpone",
            accessor: "postpone",
            Cell: ({row})=>{
              
                return row.original.state !== "stopped"?
                (<ModalButton
                    key={"modalButtonPostpone"+1}
                    buttonColor="blue"
                    buttontitle= "Postpone Shutdown"
                    handlePassedData={handlePassedData}
                    description="Postpone Shutdown"
                    instanceId={row.original.id}
                    action={"postpone"}
                    adminType={adminType}
                    userData={currUser}
                    instance = {row.original}
                    env={props.env}
                    />):
                    <h2>N/A</h2>
            },
           

           
          }
        ],
    [currUser, adminType]
  );

  

  return(
    <div>
    {(adminType === "-1")?(<div>
            <Text fontSize={36}>Forbidden.</Text>
        </div>):
        (!instances)? <><LoadingSkeleton /></>:
    <div>
    <Grid templateColumns='repeat(3, 1fr)' gap={6}>
    <HStack>
        <Stat data={instances} />
    </HStack>
   
  
    <Center>
   
          
        <Box>
        <Text mb='0px'>Search instances</Text>  
            <Input
                borderColor='black'
                focusBorderColor='crimson'
                placeholder='search'
                onChange={e => __handleSearch(e)} />
        </Box>    
    </Center>     
    <UserCard email={currUser.email} adminType={adminType} />       
    </Grid>
    <Divider marginBottom={5} />
    {
        currUser && <CustomTable columns={columns} data={filteredInstances} />
    }
   
    </div>
}
  </div>
  )


}

export default ReactTable;

