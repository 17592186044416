/*
tableCols represent the main table columns, their accessors and sometimes, the contents
to be mapped to the cells. These are used by ReactTable component is ReactTable.js
*/
import timeCalculator from "../utils/timeCalculator"
import EpochTimeToLocaleString from "../utils/UTCtoLocalTime"
export const tableCols= [
    {
        Header: "#",
        accessor: "index",
        Cell: ({row})=>{
            return <div>{(row.index + 1)}</div>
        }
      },
      {
        Header: "Instance Name",
        accessor: "tags.Name",
        Cell:({row})=>{
            return <div>{row.original.tags.Name}</div>
        }
       
      },
      {
        Header: "Instance ID",
        accessor: "id"     
      },
      {
        Header: "Region",
        accessor: "region"
       
      },
      {
        Header: "User",
        accessor: "user"
      },
      {
        Header: "Time Requested",
        accessor: "daysRequested",
      },
 
      {
        Header: "Time Since Start",
        accessor: "hoursSinceStart"


      },
      {
        Header: "Justification",
        accessor: "justification"
      },
      {
        Header: "Time Until Shutdown",
        accessor: "hoursTillShutdown",
      },
      {
        Header: "Start Time",
        accessor: "startTime"
        
        
      },

      {
        Header: "Current Status",
        accessor: "state"
       
      },
]