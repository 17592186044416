
import EpochTimeToLocaleString from "./UTCtoLocalTime"
import timeCalculator from "./timeCalculator"
export default function JSONtoCSV(items, columns, hiddenCols){
    // handle null values here.
    const replacer = (key, value) => value === null ? '' : value

    // extract only values array from the items (contains other information too)
    let values = items.map(e => e.values)
    let original = items.map(e => e.original)
    console.log("original")
    console.log(original)
    console.log(values)
    //replace the UTC epoch time in the values array with proper local time
    values = values.map(e => replaceUTCWithLocaleTime(e))

    //filter out the columns that are hidden in the current view
    let colSelectors = columns.filter(value => !hiddenCols.includes(value.accessor))
    
    // filter out functional columns (e.g. start/stop/postpone)
    colSelectors = colSelectors.filter(value => !['startstop', 'postpone', 'index'].includes(value.accessor))

    // Create header for the CSV file
    const header = ['Nr',colSelectors.map(elem => elem.Header )]
    
    // first add the header, then add rest of the data.
    const csv = [
    header.join(','), // header row first
    ...values.map((row,index) => [index+1,colSelectors.map((headerAndAccessor) => JSON.stringify(row[headerAndAccessor.accessor], replacer))].join(','))
    ].join('\r\n')

    // create a blob with the CSV data
    let blob = new Blob([csv], {type: "text/csv"})

    // create a download link
    let csvURL = window.URL.createObjectURL(blob)
    let linkForData = document.createElement('a')
    linkForData.href = csvURL
    let currentDateTime = new Date().toLocaleDateString()
    linkForData.setAttribute('download', `sandbox_export_${currentDateTime}.csv`)
    //click on download link automatically
    linkForData.click()

}
/*
    Takes the value row and replaces the shutdown to readable date time format and keeps the rest.
*/
function replaceUTCWithLocaleTime(valueRow){
    let ret = valueRow
    ret["tags.shutdown"]?ret["tags.shutdown"]=EpochTimeToLocaleString(ret["tags.shutdown"]):ret["tags.shutdown"]= ""
    return ret
}

