import { Box, Heading, Stack, Text } from '@chakra-ui/react'

export const Stat = (props) => {
  console.log("statistics")
  console.log(props)
  const { label, value, ...boxProps } = props
  let total = props.data.length
  let running = props.data.reduce((acc, curr)=>curr.state==="running"?acc=acc+1:acc=acc+0,0)
  let shutDown = total-running
 
  return (
    <>
    <Box
      px={{
        base: '4',
        md: '6',
      }}
      py={{
        base: '5',
        md: '6',
      }}
      bg="bg-surface"
      borderRadius="lg"
      boxShadow="sm"
     
    >
      <Stack>
        <Text fontSize="sm" color="muted">
          {"Total Instances"}
        </Text>
        <Heading
          size={{
            base: 'sm',
            md: 'md',
          }}
        >
          {total}
        </Heading>
      </Stack>
      
    </Box>
       <Box
       px={{
         base: '4',
         md: '6',
       }}
       py={{
         base: '5',
         md: '6',
       }}
       bg="bg-surface"
       borderRadius="lg"
       boxShadow="sm"
      
     >
       <Stack>
         <Text fontSize="sm" color="muted">
           {"Running"}
         </Text>
         <Heading
           size={{
             base: 'sm',
             md: 'md',
           }}
         >
           {running} ({Math.round(running/total * 100)}%)
         </Heading>
       </Stack>
       
     </Box>

     <Box
       px={{
         base: '4',
         md: '6',
       }}
       py={{
         base: '5',
         md: '6',
       }}
       bg="bg-surface"
       borderRadius="lg"
       boxShadow="sm"
      
     >
       <Stack>
         <Text fontSize="sm" color="muted">
           {"Shut Down"}
         </Text>
         <Heading
           size={{
             base: 'sm',
             md: 'md',
           }}
         >
           {shutDown} ({Math.round(shutDown/total * 100)}%)
         </Heading>
       </Stack>
       
     </Box>

     
     
     </>
  )
}
