
import './App.css';

import {HStack, Button, Text, Stack} from '@chakra-ui/react'
import WithSubnavigation from './componenents/navigation';
import { useState, useEffect} from 'react';
import { FaGoogle } from 'react-icons/fa';
import DashboardCard from './componenents/dashbaord_card'
import ec2 from '../src/images/ec2.svg'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactTable from './componenents/ReactTable';
import { GoogleLogin, googleLogout, useGoogleOneTapLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { envs } from './data/environments';



function App(props) {
  const [user, setUser] = useState(localStorage.email?{"email": localStorage.email, "id_tok": localStorage.id_tok}:null)
  useEffect(() =>{


  },[])


  return (
    <div className="App">
    <BrowserRouter>
  {
    /*
      Login out button depending upon if signed in or signed out
    */
  }
  <WithSubnavigation className="Navbar" user={user} signInOut={
      !user?<GoogleLogin
      auto_select={true} 
      onSuccess={credentialResponse => {
        var decoded = jwt_decode(credentialResponse.credential);
        setUser({"email": decoded.email, "id_tok": credentialResponse.credential})
        localStorage.setItem("email", decoded.email)
        localStorage.setItem("id_tok", credentialResponse.credential )
      }}
      onError={() => {
        console.log('Login Failed');
      }}
      useOneTap
    />:<Button
        background={"red"}
        color = {"whiteAlpha.900"}
        onClick={() => {
          googleLogout()
          localStorage.removeItem("email")
          localStorage.removeItem("id_tok")
          setUser(null)
        }}
     >
      Sign Out
     </Button>
    }>
  </WithSubnavigation>




  {(user || localStorage.email)?
  ( <div
      className='contentDiv'>
      <Routes>
        <Route
          path="/" element={
            <div className='rootContainer'>

              <HStack 
                spacing='24px'>
                {
                  envs.map(el=>{
                    return <DashboardCard 
                    path= {el.endpoint}
                    imageSource={ec2}
                    description = {el.description}
                  />
                  })
                }

              </HStack>
            
          
            </div>
      
          } />

        {
          envs.map(el=>{
            return (
            <Route
              path = {el.endpoint}
              element = {
                <div>
                  {
                    <ReactTable
                      user={user}
                      env={el}
                    />

                    
                  }
                </div>
              }
            >

            </Route>)
          })
        }
      </Routes>
  </div>): 
  <div>
    <Stack
      spacing={3}>
      <Text 
        fontSize='6xl'>
          Welcome to Acrolinx SRE Dashboard
      </Text>
      <Text
        fontSize='5xl'>
        Please login to continue
      </Text>
    </Stack>
  </div>}
    
    </BrowserRouter>
    </div>

    
   
  );
}

export default App;
