
/*
This array includes the columns hidden by default on rendering the
React Table component.
The entries are accessor to the table columns and can be found on the
React table component. Add the accessor attribute's value for the column here
in order to hide the column on render. The accessors can be found in the data/tableCols.js
or in ReactTable.js.
*/
export const defaultHiddenCols = [
    'region',
     'id', 
     'startDate', 
     'hoursSinceStart', 
     'hoursTillShutdown', 
     'user',
     'daysRequested'
    ]
