
import { useTable, usePagination, useSortBy } from "react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  IconButton,
  Text,
  Tooltip,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  RadioGroup,
  Radio,
  Stack,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Box,
  AccordionIcon,
  Checkbox,
  VStack,
  Button

} from "@chakra-ui/react";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  ChevronDownIcon
} from "@chakra-ui/icons";

import { useState, useEffect } from "react";
import { defaultHiddenCols } from "../data/defaultHiddenCols";

import ColumnChooser from "./columnChooser";
import FileExporter from "./fileExporter";
import { PropaneSharp } from "@mui/icons-material";
/*
Custom table visualisation for the instances
Contains the control pane (page navigation, column chooser)
is imported to ReactTable

*/


function CustomTable({ columns, data }) {
  
  // keep track of which cols are selected to be hidden
  // edit the file "../data/defaultHiddenCols" to add / remove default hidden cols.
  const [hiddenCols, setHiddenCols] = useState(defaultHiddenCols)
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page
  
      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
      setHiddenColumns,
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0,
        hiddenColumns: hiddenCols },
    
      },
      useSortBy,
      usePagination
   
    );

    useEffect(() =>{
     //monitor for changes in the hidden cols list, render so that
     // the cols can be hidden / shown 
      setHiddenColumns(hiddenCols)
    },[hiddenCols])

    
    function getDataFromColChooser(colChooserData){
      setHiddenCols(colChooserData)
    }


    // Render the UI for your table
    return  (
      
      <>
    {
      /*
      The control bar for the table settingss. page control, column chooser, etc.
      */
      <Flex justifyContent="space-between" m={4} alignItems="center">
        
          <Flex>
            <Tooltip label="First Page">
              <IconButton
                onClick={() => gotoPage(0)}
                isDisabled={!canPreviousPage}
                icon={<ArrowLeftIcon h={3} w={3} />}
                mr={4}
              />
            </Tooltip>
            <Tooltip label="Previous Page">
              <IconButton
                onClick={previousPage}
                isDisabled={!canPreviousPage}
                icon={<ChevronLeftIcon h={6} w={6} />}
              />
            </Tooltip>
          </Flex>
  
          <Flex alignItems="center">
            <Text flexShrink="0" mr={8}>
              Page{" "}
              <Text fontWeight="bold" as="span">
                {pageIndex + 1}
              </Text>{" "}
              of{" "}
              <Text fontWeight="bold" as="span">
                {pageOptions.length}
              </Text>
            </Text>
            <Text flexShrink="0">Go to page:</Text>{" "}
            <NumberInput
              ml={2}
              mr={8}
              w={28}
              min={1}
              max={pageOptions.length}
              onChange={(value) => {
                const page = value ? value - 1 : 0;
                gotoPage(page);
              }}
              defaultValue={pageIndex + 1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Select
              w={32}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 50, data?.length].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {
                  // replace pageSize with "All" if equal to #of instances
                  pageSize === data.length? `All (${pageSize})`: pageSize
                  }
                </option>
              ))}
            </Select>
            <ColumnChooser columns={columns} sendData={getDataFromColChooser} />
            <FileExporter data={rows} columns={columns} hiddenCols={hiddenCols} />
        </Flex>
        
  
          <Flex>
            <Tooltip label="Next Page">
              <IconButton
                onClick={nextPage}
                isDisabled={!canNextPage}
                icon={<ChevronRightIcon h={6} w={6} />}
              />
            </Tooltip>
            <Tooltip label="Last Page">
              <IconButton
                onClick={() => gotoPage(pageCount - 1)}
                isDisabled={!canNextPage}
                icon={<ArrowRightIcon h={3} w={3} />}
                ml={4}
              />
            </Tooltip>
          </Flex>
        </Flex>


      }
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps()}
                      {...column.getSortByToggleProps()}
                  >
                  {
                      /*
                      arrow up down icons toggle
                      */
                  }
                  <Flex alignItems="center">
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ChevronDownIcon ml={1} w={4} h={4} />
                        ) : (
                          <ChevronUpIcon ml={1} w={4} h={4} />
                        )
                      ) : (
                        ""
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
  

      </>
    );
  }
  
export {CustomTable}  