import timeCalculator from "./timeCalculator"
import EpochTimeToLocaleString from "./UTCtoLocalTime"
export function prepareDataForTable(dataArr){
    let arr = dataArr
    arr.map(
        (d, i) => {
            if(d.tags.actionInfo){
                let actionInfo = JSON.parse(d.tags.actionInfo)
                d["user"] = actionInfo.user
                d["justification"] = actionInfo.justification
                if(actionInfo.action === "start"){
                    let now = new Date()
                    let timeInEpochSecs = Math.round(now.getTime()/1000)
                    d["hoursSinceStart"] = timeCalculator(parseInt(actionInfo.timestamp), timeInEpochSecs)
                    d["daysRequested"] = timeCalculator(parseInt(actionInfo.timestamp), parseInt(d.tags.shutdown))
                    d["hoursTillShutdown"] = timeCalculator(timeInEpochSecs, parseInt(d.tags.shutdown))
                    d["startTime"] = EpochTimeToLocaleString(actionInfo.timestamp)
                    d["shutdownTime"] = EpochTimeToLocaleString(d.tags.shutdown)
                }
            }
        }
    )
    return arr
}
